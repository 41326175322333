.notification {

  $width: 300px;
  $height: 70px;
  $column-left: 100%;
  $column-right: 35px;
  $grid-template-columns: calc( #{ $column-left } - #{ $column-right } ) #{ $column-right };
  $rows: 100%;

  width: $width;
  height: $height;
  background-color: var( --color-black-100 );
  position: absolute;
  display: grid;
  grid-template-columns: $grid-template-columns;
  grid-template-rows: $rows;

  &-component {

    top: 65px;
    right: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    max-width: $width;
    height: $height;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    opacity: 0;
    transform: translate3d( #{ $width }, 0, 0 );
    transition: all 500ms cubic-bezier( 0.500, 0.250, 0.165, 1.0 );

    &--is-opened {

      opacity: 1;
      transform: translate3d( 0, 0, 0 ); } }

  &-column {

    width: 100%;
    height: 100%;
    display: flex;

    &--left {

      align-items: center;
      justify-content: center;

      span {

        margin-left: 15px;
        text-align: justify; } }

    &--right {

      align-items: flex-start;
      justify-content: center; }

    &-message {

      color: var( --color-white-100 );
      font-size: 0.7rem; } }

  &-icon {

    width: 100%;
    height: 100%;
    position: absolute;

    &--cross {

      top: 8px;
      width: 15px;
      height: 15px;
      position: absolute;
      opacity: 1;

      &:hover {

        opacity: 0.7; }

      &:before,
      &:after {

        content: ' ';
        left: 7px;
        width: 1px;
        height: 100%;
        position: absolute;
        background-color: var( --color-white-100 ); }

      &:before {

        transform: rotate( 45deg ); }

      &:after {

        transform: rotate( -45deg ); } } } }
