
.loading-renderer--card3d {

  $monsieurbadialogo: monsieurbadia-logo;

  transform: scale( 0.85 );

  .monsieurbadia-logo {

    &-mister-no-face {
      &-body {
        path {

          stroke: var( --color-gold-100 ); }

        &-head-structure {
          path {

            fill: var( --color-gold-100 ); } }

        &-head-eye-structure {
          path {

            stroke: var( --color-gold-100 ); } }

        &-head-eye-pupil {
          path {

            fill: var( --color-gold-100 ); } }

        .eye-pupil-glow {
          path {

            fill: var( --color-white-100 ); } }

        &-head-eye-iris {
          path {

            stroke: var( --color-gold-100 ); } } }

      &-title {
        text {

          fill: var( --color-gold-100 ); } } } } }

